<script setup lang="ts">
import { ToggleButtonField } from '@/components/Fields'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { EUserActions } from '@/store/userStore/UserStoreTypes'
import { getCurrentThemeMode, setCurrentThemeMode } from '@/utils/viewsUtils'
import { useDebounceFn } from '@vueuse/core'
import { computed } from 'vue'
import { EColors } from '../../types/constants/ColorValues'
import { EDebounceTime } from '../../utils/debounceUtils'

const currentData = computed(() => ({ ...store.state.authentication.me }))

const modeSwitch = computed({
    get: () =>
        (currentData.value?.data?.theme ??
            localStorage.getItem('theme') ??
            getCurrentThemeMode.value) === EColors.LIGHT
            ? true
            : false,
    set: value => {
        const themeValue = value ? EColors.LIGHT : EColors.DARK
        getCurrentThemeMode.value = themeValue
        setCurrentThemeMode(themeValue)
        debounceSaveTheme(themeValue)
        return value
    }
})

const debounceSaveTheme = useDebounceFn((theme: EColors) => {
    store.dispatch(`${EStoreModules.USER}/${EUserActions.UPDATE_USER}`, {
        data: {
            ...currentData.value,
            data: { ...currentData.value.data, theme }
        },
        userId: currentData.value.id
    })
}, EDebounceTime.DEFAULT)
</script>

<template>
    <ToggleButtonField
        passive-type="is-primary"
        type="is-warning"
        :modelValue="modeSwitch"
        @change="value => (modeSwitch = value)"
        :label="$t(`common.theme.${modeSwitch ? 'light' : 'dark'}`)"
        v-bind="$attrs" />
</template>
