import type { CheckpointModel } from '@/types/models/CheckpointModel'

export type CheckpointState = {
    checkpoint?: CheckpointModel
    checkpoints: CheckpointModel[]
}

export enum ECheckpointActions {
    FETCH_CHECKPOINT = 'fetchCheckpoint',
    FETCH_CHECKPOINTS = 'fetchCheckpoints',
    CREATE_CHECKPOINT = 'createCheckpoint',
    UPDATE_CHECKPOINT = 'updateCheckpoint',
    DELETE_CHECKPOINT = 'deleteCheckpoint',

    ERASE_DETECTIONS = 'eraseDetections'
}

export enum ECheckpointMutations {
    SET_CHECKPOINTS = 'setCheckpoints',
    SET_CHECKPOINT = 'setCheckpoint',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
