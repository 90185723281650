<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import type { EventModel } from '@/types/models/EventModel'
import { ref } from 'vue'
import SecondaryButton from '../../../../components/Buttons/SecondaryButton.vue'
import NumberInputField from '../../../../components/Fields/NumberInputField.vue'
import StackLayout from '../../../../components/Layouts/StackLayout.vue'
import { EEventActions } from '../../../../store/eventStore/EventStoreTypes'
import {
    EFiltersDevice,
    EFiltersDeviceKeysValues,
    EFiltersDevicesLabels
} from '../../../../types/enum/FiltersDeviceEnum'

type Props = {
    data: EventModel
}
const props = defineProps<Props>()

type Emits = {
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const open = ref(false)

const filtersData = ref<Record<string, number>>({})

const handleConfirm = () =>
    store
        .dispatch(`${EStoreModules.EVENT}/${EEventActions.SET_EVENT_FILTERS}`, {
            eventId: props.data.id,
            data: filtersData.value,
            name: props.data.name
        })
        .then(() => emit('refresh'))
</script>

<template>
    <PrimaryButton :label="$t('event.actions.affectFiltersTitle')" @click="open = true" />
    <BaseDialog
        :title="$t('event.actions.affectFiltersTitle')"
        :open="open"
        @confirm="handleConfirm"
        @close="open = false"
        class="event-checkpoint-component modal-selector-half-width">
        <template #content>
            <StackLayout direction="column" :gap="8">
                <TypographyItem :label="$t('event.actions.affectFilters')" />
                <StackLayout
                    direction="column"
                    v-for="(filterKey, keyItem) in EFiltersDevice"
                    :key="keyItem"
                    :gap="8">
                    <TypographyItem :label="filterKey.toUpperCase()" />
                    <StackLayout :gap="16">
                        <NumberInputField
                            v-for="(item, key) in EFiltersDeviceKeysValues"
                            :key="key"
                            :label="EFiltersDevicesLabels(filterKey, item)"
                            :modelValue="filtersData[`${filterKey}${item}`]"
                            @change="filtersData[`${filterKey}${item}`] = $event ?? undefined" />
                    </StackLayout>
                </StackLayout>
                <StackLayout></StackLayout>
            </StackLayout>
        </template>
        <template #actions>
            <SecondaryButton :label="$t('event.actions.affectFiltersDevice')" />
        </template>
    </BaseDialog>
</template>
