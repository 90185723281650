<script setup lang="ts">
import EditableButtonGroup from '@/components/Buttons/EditableButtonGroup.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import FullScreenLoader from '@/components/LoadingSpinner/FullScreenLoader.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import TabsComponent from '@/components/Tabs/TabsComponent.vue'
import { EVENTS_ROUTES } from '@/router/routes'
import { buildDetectionArray } from '@/socket/socketUtils'
import store from '@/store'
import { EEventActions } from '@/store/eventStore/EventStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { defaultEventModel, type EventModel } from '@/types/models/EventModel'
import type { PartnerConfigModel } from '@/types/models/PartnerConfigModel'
import { EDetectionsPerm } from '@/types/permissions/DetectionsPermissionsEnum'
import { EEventsPerm } from '@/types/permissions/EventsPermissionsEnum'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import type {
    SocketCheckpointDetectionModel,
    SocketDeviceDetectionModel
} from '@/types/socket/SocketDetectionModel'
import { showTab } from '@/utils/permissionsUtils'
import { isEqual } from 'lodash'
import moment from 'moment'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import EventPastDetections from './Detail/Detections/EventPastDetections.vue'
import EventCheckpoints from './Detail/EventCheckpoints.vue'
import EventDevicesCardTable from './Detail/EventDevicesCardTable.vue'
import EventInformationsTab from './Detail/EventInformationsTab.vue'
import EventTabsWithSocketWrapper from './Detail/EventTabsWithSocketWrapper.vue'

const route = useRoute()
const { t } = useI18n()
const eventId = computed(() => parseInt(route.params.id.toString()))
const eventStore = computed(() => store.state.event)
const isInEditMode = ref(false)

const data = ref<EventModel>({ ...defaultEventModel })
const getEvent = () =>
    store
        .dispatch(`${EStoreModules.EVENT}/${EEventActions.FETCH_EVENT}`, eventId.value)
        .then((res: EventModel) => {
            data.value = { ...res }
            dispatchEvent(
                new CustomEvent('recentlyVisited', {
                    bubbles: true,
                    detail: {
                        label: res.name,
                        organization_id: res.organization_id,
                        to: EVENTS_ROUTES.buildUrl(res.id)
                    }
                })
            )
        })

onMounted(() => getEvent())

const save = () => {
    if (!isEqual(data.value, eventStore.value.data.event))
        store
            .dispatch(`${EStoreModules.EVENT}/${EEventActions.UPDATE_EVENT}`, {
                eventId: eventId.value,
                data: data.value
            })
            .then(() => getEvent())
}
const handleUpdateData = (value: Partial<EventModel>) => (data.value = { ...data.value, ...value })
const handleUpdatePartnerConfig = (value: Partial<PartnerConfigModel>) =>
    (data.value.partner_config = { ...editPartnerConfig.value, ...value })

const hasDetectionViewPermission = computed(() =>
    showTab(
        {
            id: 'detections',
            label: t('event.detail.detections.detectionsTitle')
        },
        EDetectionsPerm.DETECTIONS_VIEW
    )
)
const hasDevicesViewPermission = computed(() =>
    showTab(
        {
            id: 'devices',
            label: t('event.detail.devices.devicesTitle')
        },
        EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_VIEW
    )
)

const tabs = computed(() => [
    { id: 'informations', label: t('event.detail.informations.informationsTitle') },
    {
        id: 'checkpoints',
        label: t('event.detail.checkpoints.checkpointsTitle')
    },
    ...hasDevicesViewPermission.value,
    ...hasDetectionViewPermission.value
])

const partnerConfig = computed(() =>
    eventStore.value.data.event?.partner?.config ? eventStore.value.data.event.partner : undefined
)
const editPartnerConfig = computed(() =>
    eventStore.value.data.event?.partner_config
        ? eventStore.value.data.event.partner_config
        : undefined
)

const checkpointsDetections = ref<SocketCheckpointDetectionModel[]>([])
const handleCheckpointsUpdate = (value?: SocketCheckpointDetectionModel[]) =>
    (checkpointsDetections.value = value ?? [])
const devicesDetections = ref<SocketDeviceDetectionModel[]>([])
const associatedDevices = ref<number[]>()
const handleDevicesUpdate = (value?: SocketDeviceDetectionModel[]) =>
    (devicesDetections.value = value ?? [])

const handleLatestDetection = (latestDetection: DetectionModel[]) => {
    event_detections.value = [...latestDetection, ...event_detections.value]
    if (checkpointsDetections.value.length)
        checkpointsDetections.value = buildDetectionArray<SocketCheckpointDetectionModel>(
            latestDetection,
            checkpointsDetections.value,
            'checkpoint_id'
        )
    if (devicesDetections.value.length)
        devicesDetections.value = buildDetectionArray<SocketDeviceDetectionModel>(
            latestDetection,
            devicesDetections.value,
            'device_id'
        )
}

const event_detections = ref<DetectionModel[]>([])
const currentEvent = computed(() => !!data.value.id && moment(data.value.end_at).isAfter())
</script>

<template>
    <StackLayout direction="column" :gap="8" class="relative">
        <FullScreenLoader
            :isLoading="!!eventStore.isLoading || !!store.state.checkpoint.isLoading" />
        <EventTabsWithSocketWrapper
            v-if="currentEvent"
            :events="[eventId]"
            @latestDetection="handleLatestDetection" />
        <TabsComponent :tabs="tabs">
            <template #informations>
                <StackLayout direction="column" :gap="16" class="full-width">
                    <PermissionWrapper :permission="EEventsPerm.EVENTS_EDIT">
                        <EditableButtonGroup
                            :isInEditMode="isInEditMode"
                            @save="save"
                            @edit="isInEditMode = $event" />
                    </PermissionWrapper>
                    <EventInformationsTab
                        :data="data"
                        :partnerConfig="partnerConfig"
                        :partnerConfigValues="editPartnerConfig"
                        :isInEditMode="isInEditMode"
                        @update="handleUpdateData"
                        @updatePartnerConfig="handleUpdatePartnerConfig" />
                </StackLayout>
            </template>
            <template #checkpoints>
                <EventCheckpoints
                    :currentTabIndex="tabs.findIndex(item => item.id === 'checkpoints')"
                    :detections="checkpointsDetections"
                    :partnerConfig="partnerConfig"
                    @checkpointUpdate="handleCheckpointsUpdate"
                    @refreshDevices="associatedDevices = $event" />
            </template>
            <template #devices>
                <EventDevicesCardTable
                    v-if="data.id"
                    :eventId="data.id"
                    :currentEvent="currentEvent"
                    :detections="devicesDetections"
                    :associates="associatedDevices"
                    @devicesUpdate="handleDevicesUpdate" />
            </template>

            <template #detections>
                <EventPastDetections v-if="!!data.id" :event="eventStore.data.event" />
            </template>
        </TabsComponent>
    </StackLayout>
</template>
