import type { EventModel } from '@/types/models/EventModel'

export type EventState = {
    event?: EventModel
    events: EventModel[]
}

export enum EEventActions {
    FETCH_EVENT = 'fetchEvent',
    FETCH_EVENTS = 'fetchEvents',
    CREATE_EVENT = 'createEvent',
    UPDATE_EVENT = 'updateEvent',
    DELETE_EVENT = 'deleteEvent',

    SET_EVENT_FILTERS = 'setEventFilters'
}

export enum EEventMutations {
    SET_EVENTS = 'setEvents',
    SET_EVENT = 'setEvent',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
