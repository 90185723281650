<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import OrganizationSelector from '@/components/Selector/components/OrganizationSelector.vue'
import type { AdminDeviceFiltersType } from '@/types/filters/AdminFilters'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { ref } from 'vue'

type Props = {
    filters: AdminDeviceFiltersType
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: AdminDeviceFiltersType): void
}
const emit = defineEmits<Emits>()

const selectedOrganization = ref<OrganizationModel>()
const handleSelect = (value?: OrganizationModel) => {
    selectedOrganization.value = value
    emit('change', { organization_id: value?.id })
}
</script>

<template>
    <StackLayout :gap="8" class="full-width" isResponsiveSmallScreen>
        <OrganizationSelector
            :organizationId="selectedOrganization?.id"
            @select="handleSelect"
            clearable />
    </StackLayout>
</template>
