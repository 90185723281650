<script setup lang="ts">
import { FullScreenLoader } from '@/components/LoadingSpinner'
import MenuRecentlyVisited from '@/components/Menu/MenuRecentlyVisited.vue'
import { DEVICE_ROUTES } from '@/router/routes'
import { HOME_ROUTE_NAME } from '@/router/routing/homeRoutes'
import store from '@/store'
import { EAuthActions } from '@/store/authStore/AuthStoreTypes'
import { ELoadingGetters } from '@/store/loadingStore/LoadingStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import {
    getCurrentThemeMode,
    isMobile,
    isTablet,
    isTabletEvent,
    setCurrentThemeMode
} from '@/utils/viewsUtils'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { StackLayout } from '.'
import NoticeItem from '../Alerts/NoticeItem.vue'
import MenuDrawerDesktop from '../Drawer/MenuDrawer/MenuDrawerDesktop.vue'
import MenuDrawerMobile from '../Drawer/MenuDrawer/MenuDrawerMobile.vue'
import DashboardContent from './DashboardLayout/DashboardContent.vue'
import DashboardTitle from './DashboardLayout/DashboardTitle.vue'

const showMobileMenu = ref(isMobile() || isTablet())
isTabletEvent(e => (showMobileMenu.value = e.matches))

const route = useRoute()
const router = useRouter()

const isLoading = computed(() => store.getters[ELoadingGetters.GET_IS_LOADING])

const getMe = () =>
    store.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.ME}`).then(res => {
        setCurrentThemeMode(res.data?.theme ?? getCurrentThemeMode.value)
        route.name === HOME_ROUTE_NAME &&
        res.permissions?.includes(EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_VIEW)
            ? router.replace(DEVICE_ROUTES.baseUrl)
            : undefined
    })
onMounted(() => getMe())
</script>

<template>
    <main class="flex-width app-content" :style="{ 'overflow-y': 'unset' }">
        <NoticeItem />
        <FullScreenLoader :isLoading="isLoading" />
        <StackLayout
            :direction="showMobileMenu ? 'column' : 'row'"
            class="full-width"
            :style="{ 'overflow-y': 'unset' }">
            <MenuDrawerMobile v-if="showMobileMenu" class="full-width">
                <MenuRecentlyVisited />
            </MenuDrawerMobile>
            <MenuDrawerDesktop v-else>
                <MenuRecentlyVisited />
            </MenuDrawerDesktop>
            <StackLayout
                direction="column"
                :class="`${
                    showMobileMenu ? 'has-navbar full-height' : ''
                } full-width dashboard-layout overflow-y-auto`">
                <DashboardTitle />
                <DashboardContent>
                    <RouterView />
                    <slot />
                </DashboardContent>
            </StackLayout>
        </StackLayout>
    </main>
</template>

<style scoped lang="scss">
.has-navbar {
    margin-top: var(--navbar-height);
}
.dashboard-layout {
    padding: 0 1rem 1rem 1rem;
    background-color: var(--color-background);
}
</style>
