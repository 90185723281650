import type { CheckpointLocales } from '@/types/lang/locales'

export const checkpointsEn: CheckpointLocales = {
    actions: {
        eraseDetections:
            'Re-initializing reads will erase each read currently saved. Are you sure ?',
        eraseDetectionsTitle: 'Re-initialize reads',

        reassignDevice: 'Do you want to reassign device {device} to checkpoint {checkpoint} ?',
        reassignDeviceTitle: 'Reassign device'
    },
    api: {
        error: {
            createCheckpoint: 'An error occured while creating the checkpoint {name}',
            deleteCheckpoint: 'An error occured while deleting the checkpoint {name}',
            fetchCheckpoint: 'An error occured while fetching the checkpoint',
            fetchCheckpoints: 'An error occured while fetching the checkpoints',
            updateCheckpoint: 'An error occured while updating the checkpoint {name}'
        },
        success: {
            createCheckpoint: 'Checkpoint {name} successfully created !',
            deleteCheckpoint: 'Checkpoint {name} successfully deleted !',
            fetchCheckpoint: 'Checkpoint successfully fetched',
            fetchCheckpoints: 'Checkpoints successfully fetched',
            updateCheckpoint: 'Checkpoint {name} successfully edited !'
        }
    },
    buttons: {
        showAll: 'Show all checkpoints',
        hideAll: 'Hide additional checkpoints'
    },

    model: {
        associates: 'Associated devices',
        comments: 'Comment',
        name: 'Checkpoint name'
    },
    detail: {
        associates: 'Associate a device',
        associateDevice: 'Associate device',
        noDeviceAssociated: 'No device associated',
        dissociateDevice: 'Dissociate device'
    },
    title: {
        informationsTitle: 'Information',
        associateDeviceTitle: 'Associate devices',
        partnerTitle: "Partner's information",
        actionsTitle: 'Actions'
    }
}
