export const eventsFr = {
    actions: {
        affectFilters: 'Affecter des filtres à cet évènement',
        affectFiltersTitle: 'Affecter des filtres',
        affectFiltersDevice: "Confirmer et affecter aux appareils de l'évènement"
    },
    api: {
        error: {
            createEvent: "Erreur lors de la création de l'évènement {name}",
            deleteEvent: "Erreur lors de la suppression de l'évènement {name}",
            fetchEvent: "Erreur lors de la récupération de l'évènement",
            fetchEvents: 'Erreur lors de la récupération des évènements',
            updateEvent: "Erreur lors de la modification de l'évènement {name}"
        },
        success: {
            createEvent: "Création de l'évènement {name} effectuée avec succès !",
            deleteEvent: "Suppression de l'évènement {name} effectuée avec succès !",
            fetchEvent: "Récupération de l'évènement effectuée avec succès !",
            fetchEvents: 'Récupération des évènements effectuée avec succès !',
            updateEvent: "Modification(s) de l'évènement {name} effectuée(s) avec succès !"
        }
    },
    createEvent: 'Créer un évènement',
    duplicatedEvent: 'Evènement dupliqué',
    button: {
        addCheckpoint: 'Ajouter un checkpoint',
        duplicateEvent: 'Dupliquer'
    },
    checkpoint: {
        name: 'Nom',
        comment: 'Commentaire',
        devicesList: "Liste d'appareils",
        noDetectionFound: 'Aucune détection trouvée',
        noDeviceAssociated: 'Aucun appareil associé'
    },
    detail: {
        informations: {
            informationsTitle: 'Informations',
            eventLocalization: "Localisation de l'évènement",
            partnerConfigTitle: 'Configuration du partenaire',
            partnerConfigUpdate: 'Modification du partenaire'
        },
        checkpoints: {
            checkpointsTitle: 'Checkpoints',
            createCheckpointTitle: 'Créer un nouveau checkpoint',
            partner: 'Partenaire'
        },
        detections: {
            detectionsTitle: 'Détections'
        },
        devices: {
            devicesTitle: 'Appareils'
        }
    },
    detection: {
        table: {
            bib: 'Bib',
            checkpoint: 'Checkpoint',
            timestamp: 'Date',
            device: 'Appareil',
            event_filter: 'Event filter',
            timer_filter: 'Timer filter'
        },
        liveDetections: 'Détections en direct',
        pastDetections: 'Détections précédentes'
    },
    filters: {
        archived: 'Archivé',
        clientId: 'ID du client',
        date_limit: 'Date',
        end_at: 'Date de fin',
        endAt: "Fin d'évènement",
        endDate: 'Date de fin',
        eventName: "Nom de l'évènement",
        field: 'Champ',
        future: 'Futur',
        past: 'Passé',
        period: 'Période',
        showFinished: 'Afficher les évènements terminés',
        showOngoing: 'Afficher les évènements en cours',
        showUpcoming: 'Afficher les évènements à venir',
        finished: 'Evènements terminés',
        ongoing: 'Evènements en cours',
        upcoming: 'Evènements à venir',
        start_at: "Début d'évènement",
        startAt: "Début d'évènement",
        startDate: 'Date de début'
    },
    model: {
        clientId: 'ID du client',
        comment: 'Commentaire',
        endAt: 'Date de fin',
        name: "Nom de l'évènement",
        partnerName: 'Partenaire',
        partnerId: 'ID du partenaire',
        startAt: 'Date de début',
        tz: 'Timezone'
    },
    table: {
        clientId: 'ID du Client',
        comments: 'Commentaires',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        eventName: "Nom de l'évènement",
        duplicateEvent: 'Êtes-vous sûr(e) de vouloir dupliquer cet évènement ?'
    },
    title: {
        actionsTitle: 'Actions'
    }
}
