export enum EFiltersDevice {
    F1 = 'f1',
    F2 = 'f2'
}

export enum EFiltersDeviceKeys {
    EVT_VAL = '_evt_val',
    TMR_VAL = '_tmr_val'
}

export const EFiltersDeviceValues = [EFiltersDevice.F1, EFiltersDevice.F2]
export const EFiltersDeviceKeysValues = [EFiltersDeviceKeys.EVT_VAL, EFiltersDeviceKeys.TMR_VAL]

export const EFiltersDevicesOptions = EFiltersDeviceValues.flatMap(deviceFilter =>
    EFiltersDeviceKeysValues.map(deviceKey => `${deviceFilter}${deviceKey}`)
)

export const EFiltersDevicesLabels = (filter: EFiltersDevice, filterKey: EFiltersDeviceKeys) => {
    if (filterKey.includes('evt_val')) return `EID ${filter.toUpperCase()}`
    if (filterKey.includes('tmr_val')) return `TID ${filter.toUpperCase()}`
}
