<script setup lang="ts">
import EditableButtonGroup from '@/components/Buttons/EditableButtonGroup.vue'
import SecondaryButton from '@/components/Buttons/SecondaryButton.vue'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { EKeyCategory, EKeyCategoryValues } from '@/types/enum/KeyCategoryEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { computed, inject, ref } from 'vue'
import DeviceLastPingtime from '../../Card/components/DeviceLastPingtime.vue'
import DeviceOnlineStatus from '../../Typography/DeviceOnlineStatus.vue'
import DeviceBaseActions from '../../components/DeviceBaseActions.vue'
import DeviceGlobalInformations from '../InformationsTab/components/DeviceGlobalInformations.vue'
import DeviceBottomSideConfiguration from './ConfigurationComponents/DeviceBottomSideConfiguration.vue'
import DeviceFiltersConfiguration from './ConfigurationComponents/DeviceFiltersConfiguration.vue'
import DeviceLeftSideConfiguration from './ConfigurationComponents/DeviceLeftSideConfiguration.vue'
import DeviceRightSideConfiguration from './ConfigurationComponents/DeviceRightSideConfiguration.vue'
import DeviceSaveConfigurationDialog from './ConfigurationComponents/DeviceSaveConfigurationDialog.vue'

type Props = {
    storedData?: Partial<DeviceModel>
    data: Partial<DeviceModel>
    status?: AlertSocketMessageModel
    templateKeys: DeviceTemplateKeysModel[]
    editPermission?: PermissionsEnums
    hasPermission?: boolean
}
const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update', data: Partial<DeviceModel>): void
    (e: 'refresh'): void
    (e: 'hasAdvancedConfig', value: boolean): void
    (e: 'save'): void
}>()

const updateDeviceValue = (value: Partial<DeviceModel>) => emit('update', value)

const parsedConfig = computed<Record<string, DeviceTemplateKeysModel[]>>(() =>
    EKeyCategoryValues.reduce(
        (prev: Record<string, DeviceTemplateKeysModel[]>, current: EKeyCategory) => {
            const filteredConfig = props.templateKeys?.filter(
                configItem => configItem.category === current
            )
            return filteredConfig?.length
                ? {
                      ...prev,
                      [current]: filteredConfig
                  }
                : prev
        },
        {}
    )
)

const isMobileLayout = ref(isMobile())
isMobileEvent(e => (isMobileLayout.value = e.matches))

const refs = ref<any>({
    filtersRef: undefined,
    leftSideRef: undefined,
    rightSideRef: undefined,
    bottomSideRef: undefined
})

const hasError = computed(
    () =>
        refs.value?.filtersRef?.hasError ||
        refs.value?.leftSideRef?.hasError ||
        refs.value?.rightSideRef?.hasError ||
        refs.value?.bottomSideRef?.hasError
)
const hasAdvancedConfig = computed(
    () =>
        refs.value?.filtersRef?.hasAdvancedConfig ||
        refs.value?.leftSideRef?.hasAdvancedConfig ||
        refs.value?.rightSideRef?.hasAdvancedConfig ||
        refs.value?.bottomSideRef?.hasAdvancedConfig
)
defineExpose({ hasError, hasAdvancedConfig })

const dataToShow = computed(() =>
    isInEditMode.value ? props.data : props.storedData ?? props.data
)
const showAdvanced = ref(false)
const isInEditMode = ref(false)

const isResponsiveTablet = inject<boolean>('isResponsiveTablet', false)
const isResponsiveDesktop = inject<boolean>('isResponsiveDesktop', false)

const dialogRef = ref<(HTMLDivElement & { hasModifications: boolean }) | null>()
const hasModifications = computed(() => dialogRef.value?.hasModifications)

const open = ref(false)
const handleSave = () => {
    props.data.name !== props.storedData?.name ||
    (hasModifications.value && !!Object.entries(hasModifications.value).length)
        ? (open.value = true)
        : (isInEditMode.value = false)
}

const handleDialogConfirm = () => {
    emit('save')
    open.value = false
}
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <StackLayout :gap="8" class="align-self-end" isResponsive>
            <PermissionWrapper :permission="editPermission" :hasPermission="hasPermission">
                <EditableButtonGroup
                    :isInEditMode="isInEditMode"
                    @save="handleSave"
                    @edit="isInEditMode = $event" />
            </PermissionWrapper>
            <SecondaryButton
                v-if="hasAdvancedConfig"
                :label="$t(`device.buttons.${showAdvanced ? 'hideAdvanced' : 'showAdvanced'}`)"
                @click="showAdvanced = !showAdvanced" />
        </StackLayout>
        <StackLayout
            direction="column"
            :gap="16"
            isResponsive
            :isResponsiveTablet="isResponsiveTablet"
            :isResponsiveDesktop="isResponsiveDesktop">
            <StackLayout
                direction="row"
                :gap="16"
                isResponsive
                :isResponsiveTablet="isResponsiveTablet"
                :isResponsiveDesktop="isResponsiveDesktop">
                <InformationBaseCard
                    :label="$t('device.detail.informations.informationsTitle')"
                    :class="`full-width fit-height ${isMobileLayout ? 'order-2' : 'order-1'}`">
                    <DeviceGlobalInformations
                        :data="dataToShow"
                        :isInEditMode="isInEditMode"
                        @update="updateDeviceValue">
                        <DeviceOnlineStatus
                            :status="status?.status ?? parseInt(data.status as string)" />
                        <DeviceLastPingtime :devicePingTime="status?.pingtime ?? data?.pingtime" />
                    </DeviceGlobalInformations>
                </InformationBaseCard>
                <InformationBaseCard
                    :class="`full-width fit-height ${isMobileLayout ? 'order-1' : 'order-2'}`"
                    :label="$t('common.actions')">
                    <DeviceBaseActions>
                        <StackLayout direction="column" :gap="8">
                            <slot name="actions" />
                        </StackLayout>
                    </DeviceBaseActions>
                </InformationBaseCard>
            </StackLayout>
            <DeviceFiltersConfiguration
                :ref="el => (refs.filtersRef = el)"
                :data="dataToShow"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced"
                :config="parsedConfig"
                @update="$emit('update', $event)"
                @refresh="$emit('refresh')" />
            <StackLayout
                direction="row"
                :gap="16"
                isResponsive
                :isResponsiveTablet="isResponsiveTablet"
                :isResponsiveDesktop="isResponsiveDesktop"
                class="full-width">
                <DeviceLeftSideConfiguration
                    :ref="el => (refs.leftSideRef = el)"
                    :data="dataToShow"
                    :isInEditMode="isInEditMode"
                    :showAdvanced="showAdvanced"
                    :config="parsedConfig"
                    @update="$emit('update', $event)"
                    @refresh="$emit('refresh')" />
                <DeviceRightSideConfiguration
                    :ref="el => (refs.rightSideRef = el)"
                    :data="dataToShow"
                    :isInEditMode="isInEditMode"
                    :showAdvanced="showAdvanced"
                    :config="parsedConfig"
                    @update="$emit('update', $event)"
                    @refresh="$emit('refresh')" />
            </StackLayout>
            <DeviceBottomSideConfiguration
                :ref="el => (refs.bottomSideRef = el)"
                :data="dataToShow"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced"
                :config="parsedConfig"
                @update="$emit('update', $event)"
                @refresh="$emit('refresh')" />
        </StackLayout>
    </StackLayout>

    <DeviceSaveConfigurationDialog
        ref="dialogRef"
        :open="open"
        :storedData="{ ...storedData?.config, name: storedData?.name }"
        :data="{ ...data.config, name: data.name }"
        :hasError="hasError"
        :templateKeys="templateKeys"
        @save="handleDialogConfirm"
        @close="open = false" />
</template>

<style scoped lang="scss">
.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}
</style>
