<script setup lang="ts">
import IconButton from '@/components/Buttons/IconButton.vue'
import DeviceCard from '@/components/DeviceComponents/Card/DeviceCard.vue'
import { GridLayout, StackLayout } from '@/components/Layouts'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import { useGridSize } from '@/hooks/useGridSize'
import { EColors } from '@/types/constants/ColorValues'
import { EGridSize } from '@/types/enum/ViewsUtilsEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import type { SocketDeviceDetectionModel } from '@/types/socket/SocketDetectionModel'
import { addToQuery } from '@/utils/queryUtils'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import DeviceDetailView from '@/views/DevicesView/DeviceDetailView.vue'
import { Pane, Splitpanes } from 'splitpanes'
import { provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

type Props = {
    data: DeviceModel[]
    statuses: AlertSocketMessageModel[]
    detections?: SocketDeviceDetectionModel[]
    loading?: boolean
    showNextEvent?: boolean
    showNextCheckpoint?: boolean
    editPermission?: PermissionsEnums
    editButtonHref?: string
}
defineProps<Props>()
type Emits = {
    (e: 'delete', device: DeviceModel): void
}
defineEmits<Emits>()

const route = useRoute()
const selectedDevice = ref<Partial<DeviceModel>>()
watch(
    () => route.query.preview,
    newPreviewId =>
        newPreviewId
            ? (selectedDevice.value = { device_id: parseInt(newPreviewId.toString()) })
            : (selectedDevice.value = undefined),
    { immediate: true }
)

const gridSize = useGridSize({
    smallscreen: EGridSize.IS_MOBILE,
    mobile: EGridSize.IS_MOBILE,
    tablet: EGridSize.IS_TABLET - 1,
    desktop: EGridSize.IS_DESKTOP - 1,
    widescreen: EGridSize.IS_WIDESCREEN - 1
})

const getDeviceStatus = (device: Partial<DeviceModel>) => ({
    battery: device.battery,
    plugged: device.plugged,
    signal: device.signal,
    network_type: device.network_type
})

const handleSelectDevice = (device: DeviceModel) => {
    addToQuery({ preview: device.device_id ?? device.id })
    selectedDevice.value = device
}

const isMobileLayout = ref(isMobile())
isMobileEvent(e => (isMobileLayout.value = e.matches))

provide('isResponsiveTablet', ref(true))
provide('isResponsiveDesktop', ref(true))
</script>

<template>
    <splitpanes vertical>
        <pane min-size="15">
            <StackLayout
                direction="column"
                :gap="8"
                :class="`left-side ${selectedDevice ? 'outlook-mode' : ''}`">
                <div :class="`${selectedDevice ? 'outlook-layout' : ''} full-width`">
                    <GridLayout
                        :maxItemPerRow="isMobileLayout || !selectedDevice ? gridSize : undefined"
                        :gap="8"
                        class="relative full-width grid-outlook">
                        <LoadingSpinner :isLoading="!!loading" />
                        <DeviceCard
                            v-for="(device, key) in data"
                            :key="key"
                            :data="device"
                            :status="{
                                status: parseInt(device.status as string),
                                ...getDeviceStatus(device),
                                ...(statuses?.find(
                                    item => item.device_id === (device.device_id ?? device.id)
                                ) ?? {})
                            }"
                            :detections="
                                detections?.find(
                                    item => item.device_id === (device.device_id ?? device.id)
                                )?.detections ?? []
                            "
                            :showNextEvent="showNextEvent"
                            :showNextCheckpoint="showNextCheckpoint"
                            @click="handleSelectDevice(device)"
                            :class="`full-width ${
                                (route.query.preview
                                    ? parseInt(route.query.preview?.toString())
                                    : selectedDevice?.device_id ?? selectedDevice?.id) ===
                                (device.device_id ?? device.id)
                                    ? 'selected-device'
                                    : undefined
                            }`">
                            <template #content>
                                <slot name="cardContent" v-bind="device" />
                            </template>
                        </DeviceCard>
                    </GridLayout>
                </div>
                <slot />
            </StackLayout>
        </pane>
        <!-- <DividerItem v-if="selectedDevice" orientation="vertical" variant="middle" isResponsive /> -->
        <pane min-size="25" v-if="selectedDevice && !isMobileLayout">
            <StackLayout class="right-side full-width">
                <StackLayout v-if="selectedDevice" direction="column" :gap="8" class="full-width">
                    <IconButton
                        icon="arrow-right"
                        :type="EColors.PRIMARY"
                        @click="selectedDevice = undefined"
                        class="fit-content" />
                    <slot
                        name="outlookMode"
                        v-bind="{
                            device: {
                                ...selectedDevice,
                                ...getDeviceStatus(selectedDevice),
                                ...(statuses?.find(
                                    item =>
                                        item.device_id ===
                                        (selectedDevice?.device_id ?? selectedDevice?.id)
                                ) ?? {})
                            }
                        }">
                        <DeviceDetailView
                            :device="selectedDevice"
                            isOutlookMode
                            class="full-width" />
                    </slot>
                </StackLayout>
            </StackLayout>
        </pane>
    </splitpanes>
    <BaseDialog
        :open="!!selectedDevice && isMobileLayout"
        @close="selectedDevice = undefined"
        full-screen
        class="outlook-mode-modal"
        noFooter>
        <template #content>
            <StackLayout direction="column" :gap="8" class="full-height">
                <IconButton
                    icon="arrow-right"
                    :type="EColors.PRIMARY"
                    @click="selectedDevice = undefined"
                    class="fit-content" />
                <slot name="outlookMode" v-bind="{ device: selectedDevice }" />
            </StackLayout>
        </template>
    </BaseDialog>

    <StackLayout v-if="!data?.length" class="align-self-center no-record">
        <LoadingSpinner :isLoading="!!loading" />
        {{ $t('common.noRecord') }}
    </StackLayout>
</template>

<style scoped lang="scss">
.device-table {
    overflow-y: auto;
}
.left-side {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 0 0 8px 8px;
}
.right-side {
    height: 100%;
    overflow-y: auto;
    padding: 0 8px 8px 0;
}
.grid-outlook {
    overflow-y: auto;
}

.selected-device {
    border-width: 4px;
    background-color: var(--color-background-primary);
}

.outlook-mode {
    direction: rtl;
}

.outlook-layout {
    direction: ltr;
    container: outlook-layout / inline-size;
}

@container outlook-layout (width < 400px) {
    .grid-class {
        grid-template-columns: repeat(1, 1fr);
    }
}
@container outlook-layout (width > 400px) {
    .grid-class {
        grid-template-columns: repeat(2, 1fr);
    }
}
@container outlook-layout (width > 600px) {
    .grid-class {
        grid-template-columns: repeat(3, 1fr);
    }
}

.outlook-mode-modal {
    & :deep(.device-detail) {
        height: 100%;
        overflow-y: auto;
    }
    & :deep(.b-tabs) {
        height: 100%;
    }
    & :deep(.tab-content) {
        height: 100%;
        overflow-y: auto;
        padding-right: 8px;
    }

    & :deep(.full-table-layout) {
        padding: unset;
    }
}

.split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}
</style>
