import type { ExtendedPrimitives } from '../ExtendedPrimitives'
import type { TokenType } from '../TokenType'
import type { EStatus } from '../enum/StatusEnum'
import type { PermissionsEnums } from '../permissions/PermissionsEnum'

export type UserModel = {
    active: EStatus
    data?: Record<string, ExtendedPrimitives>
    email: string
    id: number
    last_login_date: Date | null
    name: string
    organization_id?: number
    organization_name?: string
    permissions: PermissionsEnums[]
    roles: string[]
    user_id?: number
    validation_date: Date | null
}

export const defaultUserModel: UserModel = {
    active: 0,
    id: 0,
    email: '',
    name: '',
    roles: [],
    permissions: [],
    validation_date: null,
    last_login_date: null
}

export type UserDataModel = UserModel &
    TokenType & {
        permissions: string[]
        roles: string[]
        impersonate: any
    }
